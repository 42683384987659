import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Container,
  Button,
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TextWrapperForAbout,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  ButtonContainer,
} from "../../styles/globalStyles";

import { ThreeDots } from "react-loader-spinner"; 
import Max1 from "../../assets/pics/max1.jpeg";
import Max2 from "../../assets/pics/max2.jpeg";
import Max3 from "../../assets/pics/max3.jpeg";
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ImageLoaderWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
`;

const CustomImg = styled.img`
  // ... (other existing styles)
  border-radius: 16px;
  width: 200px; // or any value you prefer
  height: 200px; // or any value you prefer
  object-fit: cover; // or 'contain' to fit entire image
  padding: 12px;
`;
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
const About = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImgLoaded(true);
  };

  scrollToTop();

  return (
    <>    
    
    {/* {!imgLoaded && (
          <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />
        )}
     */}
      <InfoSec lightBg={false}>
        <Container>
          <InfoRow imgStart={""}>
            <TextWrapperForAbout>
              {/* <FlexContainer>

              </FlexContainer> */}
              {/* <Subtitle lightTextDesc={true}>
                🎓 CS @ Yeshiva University Bachelor of Science, 2020-24
              </Subtitle>
              <Subtitle lightTextDesc={true}>
                💻 Prev. 2022 Software Development Intern @ RJ Reliance
              </Subtitle>
              <Subtitle lightTextDesc={true}>📍 Located in NY and PHX</Subtitle> */}
              <CustomImg
                src={Max3}
                alt="Max Friedman"
                onLoad={handleImageLoaded}
                style={imgLoaded ? {} : { display: "none" }}
              />
              <Heading lightText={true}>About Me</Heading>

              <Subtitle lightTextDesc={true}>
                Hi 👋, my name is Max Friedman. I’m a software engineering
                student currently pursuing a BS in Computer Science.
              </Subtitle>
              {/* <Subtitle lightTextDesc={true}>
                I’m incredibly passionate about technology and specialize in
                working with Java, ReactJS, and Python. Other than technology, I
                keep busy playing acoustic guitar, working on the Music Club at
                my university, and keeping active with basketball.
              </Subtitle> */}
              <Subtitle lightTextDesc={true}>
                I made this app as a helpful tool to enhance my own guitar
                playing when playing my favorite tracks. Hopefully others can
                use it as well.
              </Subtitle>
              <Heading lightText={true}>Links</Heading>
              <Subtitle lightTextDesc={true}>
                <ul style={{ listStyleType: "disc", paddingLeft: "0px" }}>
                  <li style={{ marginBottom: "30px" }}>
                    <a
                      href="http://maxefriedman.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit" }}
                    >
                      Portfolio
                    </a>
                  </li>

                  <li style={{ marginBottom: "30px" }}>
                    <a
                      href="https://www.linkedin.com/in/max-friedman-98a77a205/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit" }}
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li style={{ marginBottom: "30px" }}>
                    <a
                      href="https://github.com/MaxF8"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit" }}
                    >
                      GitHub
                    </a>
                  </li>
                </ul>
              </Subtitle>

              {/* <Subtitle lightTextDesc={true}>
                Songseekr: I created this application out of a personal need to
                quickly find musical information to songs I liked while playing
                guitar. Designed full stack app which securely connects to the
                Spotify API, providing dynamic musical information about a
                specific user's Spotify catalog.
              </Subtitle> */}

              {/* <Heading lightText={true}>Other</Heading>
              <Subtitle lightTextDesc={true}>
                MediMind: In the 2022 YU Hackathon, along with a small team, I created an app designed to remind patients to take medicine.
                The app was created with Python and JavaScript, with Flask as the backend and React as the frontend.
              </Subtitle> */}
            </TextWrapperForAbout>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default About;
