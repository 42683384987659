import styled, { createGlobalStyle } from "styled-components";

import { Link } from "react-router-dom";

const GlobalStyle = createGlobalStyle`

${
  "" /* @font-face {
    font-family: 'Cir cularSpotifyTxTBook';
    src: url('./fonts/Gotham-Spotify-Font/GothamMedium_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
} */
}

  html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    ${"" /* font-family: 'CircularSpotifyTxTBook', sans-serif; */}
  }
  
  
  * {
    box-sizing: border-box;
    margin: 0;

    padding: 0;
    ${"" /* font-family: 'Source Sans Pro', sans-serif; */}   
    ${"" /* font-family: 'CircularSpotifyTxTBook', sans-serif; */}

  }
`;

export const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding-right: 5%; // from pixels to percent
  padding-left: 5%; // from pixels to percent
  @media screen and (max-width: 991px) {
    padding-right: 3%; // from pixels to percent
    padding-left: 3%; // from pixels to percent
  }
`;

export const InfoSec = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#101522")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75px 0;

  min-height: 100vh;
`;

export const PageMain = styled.div`
  color: #fff; // Changed to black
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#101522")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  min-height: 100vh;

  ${"" /* min-height: 54vh; */}
  ${"" /* background: '#fff';  // Always white */}
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; // Center vertically
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  margin-top: -20%; // Negative margin to shift content upwards
`;
export const TextWrapperForAbout = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  margin-top: -15%; // Negative margin to shift content upwards
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 28px; // Original size
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};

  @media screen and (max-width: 725px) {
    font-size: 24px; // Slightly smaller for small screens if needed
  }
`;
export const Subtitle = styled.p`
  max-width: 480px;
  margin-bottom: 35px;
  font-size: 18px; // Original size
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};

  @media screen and (max-width: 725px) {
    max-width: 320px;
  }
`;


export const Button = styled.button`
  border-radius: 5px;
  min-width: 155px; // Minimum width
  height: 50px; // Fixed height
  display: flex;
  text-decoration: none;
  align-items: center;
  margin: 10px;
  justify-content: center;
  background: ${({ primary }) => (primary ? "#0467FB" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "10px 48px" : "8px 18px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "18px" : "14px")};
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 0; // Remove left padding inside the button
  padding-right: 0; // Remove right padding inside the button
  margin-left: 0; // Remove left margin between buttons
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#4273BD" : "#ffffff")};
  }
  @media screen and (min-width: 961px) {
    width: 30%; // Percentage-based width for larger screens
  }

  @media screen and (max-width: 960px) {
    width: 45%; // Adjust based on your preference for medium screens
  }

  @media screen and (max-width: 725px) {
    width: 100%; // Full width for smaller screens
    padding: ${({ big }) =>
      big ? "8px 24px" : "6px 12px"}; // Adjust padding for smaller screens
    font-size: ${({ fontBig }) =>
      fontBig ? "16px" : "12px"}; // Adjust font size for smaller screens
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row; // By default, display buttons in a row
  justify-content: center; // Center align if you want the buttons to be centered
  flex-wrap: wrap; // Wrap to the next line if there's not enough space

  @media screen and (max-width: 725px) {
    flex-direction: column; // Display buttons in a column on smaller screens
    align-items: center; // Center align the buttons in the column
  }
`;

export const SmallButton = styled.button`
  border-radius: 5px;
  width: 100;
  height: 40px;
  display: flex;
  text-decoration: none;
  align-items: center;
  margin: 10px;
  justify-content: center;
  background: ${({ primary }) => (primary ? "#0467FB" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "8px 20px" : "6px 12px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "16px" : "12px")};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#4273BD" : "#ffffff")};
  }

  @media screen and (max-width: 725px) {
    width: 70%; // Constrained width for the hamburger menu
    height: 60px; // Increased height for the hamburger menu
    margin: 5px auto; // Centered with auto margin for the hamburger menu
  }
`;

export const ChordsImage = styled.img`
  width: 430px;
  height: auto;
  border-radius: 10px;

  @media screen and (max-width: 725px) {
    width: 375px;
  }
`;

export const SongGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 725px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; // Center the contents for all screen sizes
    text-align: center; // Center the text for all screen sizes

    @media screen and (max-width: 725px) {
      width: 100%;
    }
  }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 725px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const ButtonLink = styled(Link)`
  color: #fff; // Set the text color to white
  text-decoration: none; // Remove the underline
`;

export default GlobalStyle;
