import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { InfoSec, SongGrid, ChordsImage } from "../../styles/globalStyles";
import ScrollToTop from "../../ScrollToTop.js";
import SpotifyLogo from "../../assets/Spotify_Logo_White.png";

// Minor scales
import CMinorPent from "../../assets/pentatonicScales/minor/C/C.png";
import CSharpMinorPent from "../../assets/pentatonicScales/minor/CSharp/CSharp.png";
import DMinorPent from "../../assets/pentatonicScales/minor/D/D.png";
import EbMinorPent from "../../assets/pentatonicScales/minor/EFlat/EFlat.png";
import EMinorPent from "../../assets/pentatonicScales/minor/E/E.png";
import FMinorPent from "../../assets/pentatonicScales/minor/F/F.png";
import FSharpMinorPent from "../../assets/pentatonicScales/minor/FSharp/FSharp.png";
import GMinorPent from "../../assets/pentatonicScales/minor/G/G.png";
import AFlatMinorPent from "../../assets/pentatonicScales/minor/AFlat/AFlat.png";
import AMinorPent from "../../assets/pentatonicScales/minor/A/A.png";
import BbMinorPent from "../../assets/pentatonicScales/minor/BFlat/BFlat.png";
import BMinorPent from "../../assets/pentatonicScales/minor/B/B.png";

// Major scales
import CMajorPent from "../../assets/pentatonicScales/major/C/C.png";
import CSharpMajorPent from "../../assets/pentatonicScales/major/CSharp/CSharp.png";
import DMajorPent from "../../assets/pentatonicScales/major/D/D.png";
import EbMajorPent from "../../assets/pentatonicScales/major/EFlat/EFlat.png";
import EMajorPent from "../../assets/pentatonicScales/major/E/E.png";
import FMajorPent from "../../assets/pentatonicScales/major/F/F.png";
import FSharpMajorPent from "../../assets/pentatonicScales/major/FSharp/FSharp.png";
import GMajorPent from "../../assets/pentatonicScales/major/G/G.png";
import AFlatMajorPent from "../../assets/pentatonicScales/major/AFlat/AFlat.png";
import AMajorPent from "../../assets/pentatonicScales/major/A/A.png";
import BbMajorPent from "../../assets/pentatonicScales/major/BFlat/BFlat.png";
import BMajorPent from "../../assets/pentatonicScales/major/B/B.png";
// Minor scales
import CMinorPentFretboard from "../../assets/pentatonicScales/minor/C/CFretboard.png";
import CSharpMinorPentFretboard from "../../assets/pentatonicScales/minor/CSharp/CSharpFretboard.png";
import DMinorPentFretboard from "../../assets/pentatonicScales/minor/D/DFretboard.png";
import EbMinorPentFretboard from "../../assets/pentatonicScales/minor/EFlat/EFlatFretboard.png";
import EMinorPentFretboard from "../../assets/pentatonicScales/minor/E/EFretboard.png";
import FMinorPentFretboard from "../../assets/pentatonicScales/minor/F/FFretboard.png";
import FSharpMinorPentFretboard from "../../assets/pentatonicScales/minor/FSharp/FSharpFretboard.png";
import GMinorPentFretboard from "../../assets/pentatonicScales/minor/G/GFretboard.png";
import AFlatMinorPentFretboard from "../../assets/pentatonicScales/minor/AFlat/AFlatFretboard.png";
import AMinorPentFretboard from "../../assets/pentatonicScales/minor/A/AFretboard.png";
import BbMinorPentFretboard from "../../assets/pentatonicScales/minor/BFlat/BFlatFretboard.png";
import BMinorPentFretboard from "../../assets/pentatonicScales/minor/B/BFretboard.png";

// Major scales
import CMajorPentFretboard from "../../assets/pentatonicScales/major/C/CFretboard.png";
import CSharpMajorPentFretboard from "../../assets/pentatonicScales/major/CSharp/CSharpFretboard.png";
import DMajorPentFretboard from "../../assets/pentatonicScales/major/D/DFretboard.png";
import EbMajorPentFretboard from "../../assets/pentatonicScales/major/EFlat/EFlatFretboard.png";
import EMajorPentFretboard from "../../assets/pentatonicScales/major/E/EFretboard.png";
import FMajorPentFretboard from "../../assets/pentatonicScales/major/F/FFretboard.png";
import FSharpMajorPentFretboard from "../../assets/pentatonicScales/major/FSharp/FSharpFretboard.png";
import GMajorPentFretboard from "../../assets/pentatonicScales/major/G/GFretboard.png";
import AFlatMajorPentFretboard from "../../assets/pentatonicScales/major/AFlat/AFlatFretboard.png";
import AMajorPentFretboard from "../../assets/pentatonicScales/major/A/AFretboard.png";
import BbMajorPentFretboard from "../../assets/pentatonicScales/major/BFlat/BFlatFretboard.png";
import BMajorPentFretboard from "../../assets/pentatonicScales/major/B/BFretboard.png";
// Chords
// Minor scales
import CMinorChords from "../../assets/pentatonicScales/minor/C/CChords.png";
import CSharpMinorChords from "../../assets/pentatonicScales/minor/CSharp/CSharpChords.png";
import DMinorChords from "../../assets/pentatonicScales/minor/D/DChords.png";
import EbMinorChords from "../../assets/pentatonicScales/minor/EFlat/EFlatChords.png";
import EMinorChords from "../../assets/pentatonicScales/minor/E/EChords.png";
import FMinorChords from "../../assets/pentatonicScales/minor/F/FChords.png";
import FSharpMinorChords from "../../assets/pentatonicScales/minor/FSharp/FSharpChords.png";
import GMinorChords from "../../assets/pentatonicScales/minor/G/GChords.png";
import AFlatMinorChords from "../../assets/pentatonicScales/minor/AFlat/AFlatChords.png";
import AMinorChords from "../../assets/pentatonicScales/minor/A/AChords.png";
import BbMinorChords from "../../assets/pentatonicScales/minor/BFlat/BFlatChords.png";
import BMinorChords from "../../assets/pentatonicScales/minor/B/BChords.png";

// Major scales
import CMajorChords from "../../assets/pentatonicScales/major/C/CChords.png";
import CSharpMajorChords from "../../assets/pentatonicScales/major/CSharp/CSharpChords.png";
import DMajorChords from "../../assets/pentatonicScales/major/D/DChords.png";
import EbMajorChords from "../../assets/pentatonicScales/major/EFlat/EFlatChords.png";
import EMajorChords from "../../assets/pentatonicScales/major/E/EChords.png";
import FMajorChords from "../../assets/pentatonicScales/major/F/FChords.png";
import FSharpMajorChords from "../../assets/pentatonicScales/major/FSharp/FSharpChords.png";
import GMajorChords from "../../assets/pentatonicScales/major/G/GChords.png";
import AFlatMajorChords from "../../assets/pentatonicScales/major/AFlat/AFlatChords.png";
import AMajorChords from "../../assets/pentatonicScales/major/A/AChords.png";
import BbMajorChords from "../../assets/pentatonicScales/major/BFlat/BFlatChords.png";
import BMajorChords from "../../assets/pentatonicScales/major/B/BChords.png";

const minorKeyToImage = {
  C: [CMinorPent, CMinorPentFretboard, CMinorChords],
  "C#": [CSharpMinorPent, CSharpMinorPentFretboard, CSharpMinorChords],
  D: [DMinorPent, DMinorPentFretboard, DMinorChords],
  Eb: [EbMinorPent, EbMinorPentFretboard, EbMinorChords],
  E: [EMinorPent, EMinorPentFretboard, EMinorChords],
  F: [FMinorPent, FMinorPentFretboard, FMinorChords],
  "F#": [FSharpMinorPent, FSharpMinorPentFretboard, FSharpMinorChords],
  G: [GMinorPent, GMinorPentFretboard, GMinorChords],
  Ab: [AFlatMinorPent, AFlatMinorPentFretboard, AFlatMinorChords],
  A: [AMinorPent, AMinorPentFretboard, AMinorChords],
  Bb: [BbMinorPent, BbMinorPentFretboard, BbMinorChords],
  B: [BMinorPent, BMinorPentFretboard, BMinorChords],
};

const majorKeyToImage = {
  C: [CMajorPent, CMajorPentFretboard, CMajorChords],
  "C#": [CSharpMajorPent, CSharpMajorPentFretboard, CSharpMajorChords],
  D: [DMajorPent, DMajorPentFretboard, DMajorChords],
  Eb: [EbMajorPent, EbMajorPentFretboard, EbMajorChords],
  E: [EMajorPent, EMajorPentFretboard, EMajorChords],
  F: [FMajorPent, FMajorPentFretboard, FMajorChords],
  "F#": [FSharpMajorPent, FSharpMajorPentFretboard, FSharpMajorChords],
  G: [GMajorPent, GMajorPentFretboard, GMajorChords],
  Ab: [AFlatMajorPent, AFlatMajorPentFretboard, AFlatMajorChords],
  A: [AMajorPent, AMajorPentFretboard, AMajorChords],
  Bb: [BbMajorPent, BbMajorPentFretboard, BbMajorChords],
  B: [BMajorPent, BMajorPentFretboard, BMajorChords],
};

const SongData = () => {
  ScrollToTop();

  const location = useLocation();
  const state = location.state;
  // console.log("state");
  // console.log(state);

  const cover = state.cover;
  const trackID = state.trackID;
  const artists = state.artists;
  const artistID = state.artists.id;

  const key = state.audioFeatures.key; // access key from audioFeatures
  console.log("key: ", key);
  const mode = state.audioFeatures.mode; // access mode from audioFeatures
  const songUrl = `https://open.spotify.com/track/${trackID}`;
  const artistUrl = `https://open.spotify.com/artist/${artistID}`;

  let pentatonicImg;
  if (mode === "Minor") {
    // Minor mode
    pentatonicImg = minorKeyToImage[key];
  } else if (mode === "Major") {
    // Major mode
    pentatonicImg = majorKeyToImage[key];
  }
  console.log("pent img: ",pentatonicImg);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <InfoSec lightBg={false}>

      <Container maxWidth="md" sx={{ mb: 3 }}>
        <SongGrid>
          <div style={{ width: "50%" }}>
            <img
              src={cover}
              alt=""
              loading="lazy"
              style={{
                cursor: "pointer",
                width: "220px",
                height: "auto",
                objectFit: "cover",
                marginBottom: "1rem",
              }}
            />
            <Typography variant="h4" component="div" gutterBottom style={{}}>
              <a
                href={songUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                {state.track}
              </a>
            </Typography>
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: 0,
                color: "white",
                fontSize: "18px",
                lineHeight: "2",
              }}
            >
              <li>
                Artist(s):{" "}
                {artists?.map((artist, index) => (
                  
                  <span key={index} style={{ textDecoration: "none" }}>
                    <a
                      href={`https://open.spotify.com/artist/${artist.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline", color:"white" }}
                    >
                      {artist.name}
                    </a>
                      {console.log(artist)}

                    {index < artists.length - 1 ? ", " : ""}
                  </span>
                ))}
              </li>
              <li>
                Key: <span style={{ textDecoration: "none" }}>{key}</span>
              </li>
              <li>
                Mode:{" "}
                <span style={{ textDecoration: "none" }}>{mode}</span>
              </li>
            </ul>
          </div>
          <div style={{ width: "60%" }}>
            <Typography
              variant="h6"
              component="div"
              style={{ textDecoration: "none" }}
            >
              Chords in Key
            </Typography>
            <Box padding={0} textAlign="center">
             
              <ChordsImage
                src={pentatonicImg[2]}
                alt={`${key} ${mode} chords`}
              />
            </Box>
            <Typography
              variant="h6"
              component="div"
              style={{ textDecoration: "none" }}  
            >
              Pentatonic Shapes
            </Typography>
            <Box padding={0.75} textAlign="center">
              <img
                src={pentatonicImg[0]}
                alt={`${key} ${mode} pentatonic scale`}
                style={{
                  width: "325px",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </Box>
            <Typography
              variant="h6"
              component="span"
              style={{ textDecoration: "none" }}
            >
              Pentatonic Fretboard
            </Typography>

            <Box padding={0.75} textAlign="center">
              <img
                src={pentatonicImg[1]}
                alt={`${key} ${mode} fretboard`}
                style={{
                  width: "325px",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </Box>
          </div>
        </SongGrid>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: '100%', mt: 8, mb: -8 }} // Adjust top and bottom margins
        >
          <img
            src={SpotifyLogo}
            alt="Spotify Logo"
            style={{ height: 30, width: 'auto' }} // Adjust size as needed
          />
        </Box>
      </Container>
    </InfoSec>
  );
};

export default SongData;
