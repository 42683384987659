

import styled from 'styled-components';
import { FaDev } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container } from '../../styles/globalStyles';

export const Nav = styled.nav`
  background: #101522;
  height: 100px;
  display: flex;
  justify-content: start; /* Aligned horizontally */
  align-items: center; /* Aligned vertically */
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;
  ${Container}
`;
export const NavLogo = styled(Link)`
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* add this line */
  text-decoration: none;
  
`;


export const NavIcon = styled(FaDev)`
  margin-right: 0.5rem;
`;

export const MaxLogo = styled.img`
margin-bottom: 0.5rem;

  margin-right: 0.5rem;
  height: 1em; 
  width: auto; 
  border-radius: 10%; 
`;

export const MobileIcon = styled.div`
  display: none;
  margin: 0.5rem; // Add the same margin as the logo


  @media screen and (max-width: 725px) {
    display: block;
    position: absolute;
    top: 1;
    right: 10px;
    bottom: 16px;

    font-size: 1.8rem;
    cursor: pointer;
  }
`;


export const NavMenu = styled.ul`
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  list-style: none;
  text-align: center;


  @media screen and (max-width: 725px) { 

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 55px;
    
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #101522;
  }
`;

export const NavItemBtn = styled.li`
    margin-top: 12px; 

  @media screen and (max-width: 725px) {
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
padding-right: 32px  //hamburger menu left
    
  }
`;

export const NavLinks = styled(Link)`

  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem; // Reduced the horizontal padding

  height: 100%;
  text-decoration: none;
  padding: 0.5rem 0.5rem;  // decrease the horizontal padding
  font-size: 0.8rem; // decrease the font size

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #fff ;
      transition: all 0.3s ease;
    }
  }
`;

