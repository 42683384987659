import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThreeDots } from "react-loader-spinner";
import TableContainer from "@mui/material/TableContainer";
import { PageMain } from "../../styles/globalStyles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { Link } from "react-router-dom";
import SpotifyLogo from "../../assets/Spotify_Logo_White.png";

const numberToLetterConverter = {
  0: "C",
  1: "C#",
  2: "D",
  3: "Eb",
  4: "E",
  5: "F",
  6: "F#",
  7: "G",
  8: "Ab",
  9: "A",
  10: "Bb",
  11: "B",
};

const minorOrMajor = {
  0: "Minor",
  1: "Major",
};

const getAllTrackIDs = (data) => {
  const listOfIDs = [];
  for (let x in data) {
    if (data[x].track && data[x].track.id) {
      // Check if track and id are not null
      listOfIDs.push(data[x].track.id.toString());
    }
  }
  return listOfIDs;
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
function convertMsToMinSec(ms) {
  let minutes = Math.floor(ms / 60000);
  let seconds = ((ms % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

const PlaylistData = () => {
  scrollToTop();
  const location = useLocation();
  const {
    id: playlistID,
    cover: playlistCover,
    name: playlistName,
  } = location.state;
  const playlistUrl = `https://open.spotify.com/playlist/${playlistID}`;

  const itemsPerPage = 16;
  const [currentPage, setCurrentPage] = useState(1);
  const [token, setToken] = useState(localStorage.getItem("access_token"));
  const [fullData, setFullData] = useState([]);
  const [audioFeatures, setAudioFeatures] = useState({});
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      let fetchedData = [];
      let fetchMore = true;
      let offset = 0;
      const limit = 100;
  
      try {
        // Fetch all tracks
        while (fetchMore) {
          const response = await axios.get(`https://api.spotify.com/v1/playlists/${playlistID}/tracks?offset=${offset}&limit=${limit}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
  
          fetchedData = fetchedData.concat(response.data.items);
          offset += limit;
          fetchMore = response.data.items.length === limit;
        }
  
        // Process and store fetched data
        setFullData(fetchedData);
  
        // Split trackIDs into smaller batches if necessary and fetch audio features
        let allAudioFeatures = [];
        for (let i = 0; i < fetchedData.length; i += limit) {
          const batchTrackIDs = getAllTrackIDs(fetchedData.slice(i, i + limit));
          const audioFeaturesResponse = await axios.get(`https://api.spotify.com/v1/audio-features?ids=${batchTrackIDs.join(",")}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          allAudioFeatures = allAudioFeatures.concat(audioFeaturesResponse.data.audio_features);
        }
  
        // Create a map of audio features for easy access
        const featuresMap = allAudioFeatures.reduce((acc, feature) => {
          if (feature) {
            acc[feature.id] = {
              key: feature.key,
              mode: feature.mode,
            };
          }
          return acc;
        }, {});
  
        setAudioFeatures(featuresMap);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching playlist data:", error);
        setLoading(false);
      }
    };
  
    if (token) {
      fetchAllData();
    }
  }, [token, playlistID]);
  

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: "#101522",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots color="white" height={100} width={100} />
      </div>
    );
  }

  // Calculate current page data
  // const currentPageData = fullData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  // This assumes `fullData` is an array of track objects
  const currentPageData = fullData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <PageMain lightBg={false}>
        <Container
          maxWidth="md"
          sx={{
            mb: 3,
            "& .MuiTypography-root": {},
            "& .MuiTableCell-root": { borderBottom: "none", color: "#fff" },
          }}
        >
          <Box
            minHeight="20vh"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src={playlistCover}
              alt=""
              loading="lazy"
              style={{
                cursor: "pointer",
                width: "175px",
                height: "175px",
                objectFit: "cover",
                marginBottom: "1rem", // Adds some space between image and table
                marginRight: "1.2rem", // Adds space to the right of the image
              }}
            />
            <Typography variant="h4" component="div" gutterBottom style={{}}>
              <a
                href={playlistUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "underline" }} // This will underline the link
              >
                {/* {state.name} */}
                {playlistName}
              </a>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",

              padding: "1em",
            }}
          >
            {console.log(currentPageData)}

            <Pagination
              count={Math.ceil(fullData.length / itemsPerPage)} // Corrected to use fullData.length for total number of pages
              page={currentPage} // This ensures the Pagination component is aware of the current page
              variant="outlined"
              color="primary"
              size="large"
              onChange={(event, value) => {
                setCurrentPage(value);
                scrollToTop(); // Optionally scroll to top on page change
              }}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#fff", // Change color of text
                },
              }}
            />
          </Box>
          {/* </div> */}
          <TableContainer
            component={Paper}
            sx={{
              // maxHeight: "70vh", // Adjust this height as needed
              overflow: "auto", // Enable scrolling
              width: "100%",
              marginBottom: 3,
              backgroundColor: "#101522",
              boxShadow: "none",
            }}
          >
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow
                  style={{
                    borderTop: " 2px solid #ddd",

                    borderBottom: " 2px solid #ddd",
                  }}
                >
                  <TableCell
                    style={{
                      borderBottom: " 2px solid #ddd",
                      borderLeft: " 2px solid #ddd",
                      borderRight: " 2px solid #ddd",
                      color: "#fff",
                    }}
                  >
                    Song
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: " 2px solid #ddd",
                      borderLeft: " 2px solid #ddd",
                      borderRight: " 2px solid #ddd",
                      color: "#fff",
                    }}
                  >
                    Key
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: " 2px solid #ddd",
                      borderLeft: " 2px solid #ddd",
                      borderRight: " 2px solid #ddd",
                      color: "#fff",
                    }}
                  >
                    Mode
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: " 2px solid #ddd",
                      borderLeft: " 2px solid #ddd",
                      borderRight: " 2px solid #ddd",
                      color: "#fff",
                    }}
                  >
                    Artist
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: " 2px solid #ddd",
                      borderLeft: " 2px solid #ddd",
                      borderRight: " 2px solid #ddd",
                      color: "#fff",
                    }}
                  >
                    Duration
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map(
                  (
                    item,
                    index // Use currentPageData directly, no .items or extra slice
                  ) => (
                    <TableRow key={item.track.id} style={{}}>
                      {" "}
                      {/* Use item.track.id for a unique key */}
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: "2px solid #ddd",
                          borderLeft: "2px solid #ddd",
                          borderRight: "2px solid #ddd",
                          color: "#fff",
                        }}
                      >
                        <Link
                          to="/songData"
                          state={{
                            track: item.track.name,
                            trackID: item.track.id,
                            artists: item.track.artists, // Assuming item.track.artists is the correct path
                            audioFeatures:
                              item.track.id in audioFeatures
                                ? {
                                    // Check if item.track.id exists in audioFeatures
                                    key: numberToLetterConverter[
                                      audioFeatures[item.track.id].key
                                    ],
                                    mode: minorOrMajor[
                                      audioFeatures[item.track.id].mode
                                    ],
                                  }
                                : { key: "N/A", mode: "N/A" },
                            cover: playlistCover,
                          }}
                          style={{ color: "#fff" }}
                        >
                          {item.track.name}
                        </Link>
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "2px solid #ddd",
                          borderLeft: "2px solid #ddd",
                          borderRight: "2px solid #ddd",
                          color: "#fff",
                        }}
                      >
                        {item.track.id in audioFeatures
                          ? numberToLetterConverter[
                              audioFeatures[item.track.id].key
                            ]
                          : "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "2px solid #e0e0e0",
                          borderLeft: "2px solid #ddd",
                          borderRight: "2px solid #ddd",
                          color: "#fff",
                        }}
                      >
                        {item.track.id in audioFeatures
                          ? minorOrMajor[audioFeatures[item.track.id].mode]
                          : "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "2px solid #ddd",
                          borderLeft: "2px solid #ddd",
                          borderRight: "2px solid #ddd",
                          color: "#fff",
                        }}
                      >
                        {item.track.artists.map((artist, index) => (
                          <span key={artist.id}>
                            <a
                              href={`https://open.spotify.com/artist/${artist.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "inherit",
                                textDecoration: "underline",
                              }}
                            >
                              {artist.name}
                            </a>
                            {index < item.track.artists.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "2px solid #ddd",
                          borderLeft: "2px solid #ddd",
                          borderRight: "2px solid #ddd",
                          color: "#fff",
                        }}
                      >
                        {convertMsToMinSec(item.track.duration_ms)}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Box> */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{ width: "100%", mt: 4, mb: 2 }} // Adjust top and bottom margins
          >
            <img
              src={SpotifyLogo}
              alt="Spotify Logo"
              style={{ height: 30, width: "auto" }} // Adjust size as needed
            />
          </Box>
        </Container>
      </PageMain>
    </>
  );
};

export default PlaylistData;
