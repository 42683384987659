import React, { useState, useEffect } from "react";
import {  Routes, Route } from "react-router-dom";
import AlbumPage from "../../pages/AlbumPage";
import LikedSongPage from "../../pages/LikedSongPage";
import PlaylistData from "../../pages/PlaylistData";
import AlbumData from "../../pages/AlbumData";
import SongData from "../../pages/SongData";
import Search from "../../components/Search";
import About from "../../pages/About";


import PlaylistPage from "../../pages/PlaylistPage";

import useAuth from "../../hooks/useAuth";
import SpotifyWebApi from "spotify-web-api-node";
import HomePage from "../HomePage";

const spotifyApi = new SpotifyWebApi({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

const Main = ({ code }) => {
  const accessToken = useAuth(code);
  const [isLoggedin, setIsLoggedin] = useState(false);


  useEffect(() => {
    if (!accessToken) return;
    spotifyApi.setAccessToken(accessToken);
  }, [accessToken]);

  // const logout = () => {
  //   localStorage.clear();
  //   window.location.href = "/";
  //   console.log("logged out!");
  // };

  return (
    <>
    <div>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/playlistData" element={<PlaylistData />} />
        <Route path="/songData" element={<SongData />} />
        <Route path="/playlists" element={<PlaylistPage />} />
        <Route path="/albumData" element={<AlbumData />} />
        <Route path="/about" element={<About />} />

        <Route path="/albums" element={<AlbumPage />} />
        <Route path="/search" element={<Search />} />

        <Route path="/likedSongs" element={<LikedSongPage />} />
      </Routes>
      </div>
    </>
  );
};

export default Main;