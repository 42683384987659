import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import LoginPage from "./components/LoginPage";
import SongData from "./pages/SongData";
import AlbumData from "./pages/AlbumData";
import About from "./pages/About";



import Search from "./components/Search"; // Don't forget to import the Search component

const App = () => {
  const code = new URLSearchParams(window.location.search).get("code");

  return (
    <>
      <Router>
        <NavBar code={code} />
        <div>
        {code ? (
          <Main code={code} />
        ) : (
          <Routes>
            <Route path="/search" element={<Search />} />
            <Route path="/songData" element={<SongData />} />
            <Route path="/albumData" element={<AlbumData />} />
            <Route path="/about" element={<About />} />


            <Route path="/" element={<LoginPage />} />
          </Routes>
        )}
        </div>
        <Footer />
      </Router>
    </>
  );
};

export default App;
