import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuth = (code) => {
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [expiresIn, setExpiresIn] = useState();

  const base_url = process.env.REACT_APP_BASE_URL
  // const base_url = "http://localhost:3001"
  useEffect(() => {
    (async () => {
      try {
        // console.log("base_url:", base_url);
        // console.log(`${base_url}/login`);
        // console.log("code:", code);
  
        let response;
        try {
          response = await axios.post(`${base_url}/api/login`, { code });
          // console.log('Response:', response);
        } catch (axiosError) {
          console.error("Axios Error:", axiosError);
          throw axiosError; // You can choose to re-throw the error or handle it differently
        }
  
        const { data: { access_token, refresh_token, expires_in } } = response;
  
        // console.log("Success?");
  
        setAccessToken(access_token);
        setRefreshToken(refresh_token);
        setExpiresIn(expires_in);
        localStorage.clear();
        localStorage.setItem("access_token", access_token);
        // console.log("Success");
        window.history.pushState({}, null, '/');
      } catch (err) {
        console.log("Error Message:", err.message);
        console.log("Error Status Code:", err.response.status);
        console.log("Error Headers:", err.response.headers);
        console.log("Error Data:", err.response.data);
        console.log("Request URL:", err.request.responseURL);
        console.log("Request Method:", err.request.method);
        window.location = '/';
      }
    })();
  }, [code]);
  

  useEffect(() => {
    if (!refreshToken || !expiresIn) return;
    const interval = setInterval(async () => {
      try {
        const {
          data: { access_token, expires_in },
        } = await axios.post(`${base_url}/refresh`, {
          refreshToken,
        });
        setAccessToken(access_token);
        setExpiresIn(expires_in);
      } catch {
        window.location = '/';
      }
    }, (expiresIn - 60) * 1000);

    return () => clearInterval(interval);
  }, [refreshToken, expiresIn]);

  return accessToken;
};

export default useAuth;