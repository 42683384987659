import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "../../styles/globalStyles";
import {
  InfoSec,
  InfoRow,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrapper,
  InfoColumn,
  TopLine,
  Img,
} from "../../styles/globalStyles";

// import  {
//   NavBtnLink,
// } from "../NavBar/NavBarStyling";
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;

const LoginPage = () => {
  // const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirect_uri}&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state%20playlist-read-private`;
  const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirect_uri}&scope=user-library-read%20playlist-read-private`;

  // const goToAUTH_URL = () => {
  //   window.location.replace(AUTH_URL);
  // };
  return (
    <>
      <InfoSec lightBg={false}>
        <Container>
          <InfoRow imgStart={""}>
            <TextWrapper>
              <Heading lightText={true}>{"Connect Now"}</Heading>
              <Subtitle lightTextDesc={true}>
                {
                  "Connect to Spotify to browse musical information from your Playlists, Albums, Liked Songs, or any Album or Song on Spotify."
                }
              </Subtitle>
              {/* <Subtitle lightTextDesc={true}>
                {"Musical information is 🎸 centric, including easy access to "}
                <span style={{ textDecoration: 'underline' }}>Key</span>
                {", "}
                <span style={{ textDecoration: 'underline' }}>Mode</span>
                {", "}
                <span style={{ textDecoration: 'underline' }}>Chords</span>
                {", and "}
                <span style={{ textDecoration: 'underline' }}>Pentatonic Shapes</span>
                {" from a song in your account."}
              </Subtitle> */}
              {/* <Subtitle lightTextDesc={true} style={{ fontWeight: "bold",textDecoration: 'underline' }}>
                {
                  "Currently, the app is waiting to be reviewed by Spotify, so 'Connect' will NOT work."
                }
              </Subtitle> */}

              {/* <Subtitle lightTextDesc={true}>
                {"Alternatively, if you don't have a Spotify account, you can search any Album or Song below."}
              </Subtitle> */}
              <Subtitle lightTextDesc={true}>
                {"Alternatively, you can search any Album or Song below."}
              </Subtitle>
              <a
                href={AUTH_URL}
                style={{
                  color: "White",
                  textDecoration: "none",
                }}
              >
                <Button big fontBig primary={true}>
                  Connect
                </Button>
              </a>
              <Link to="/search" style={{ textDecoration: "none" }}>
                <Button big fontBig primary={true}>
                  {"Search"}
                </Button>
              </Link>
            </TextWrapper>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default LoginPage;
