import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import axios from "axios";

import { PageMain } from "../../styles/globalStyles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

//image list
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Pagination from "@mui/material/Pagination";
import { ThreeDots } from "react-loader-spinner"; // Don't forget to import this
import SpotifyLogo from "../../assets/Spotify_Logo_White.png";

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const ALBUMS_ENDPOINT = "https://api.spotify.com/v1/me/albums?limit=24";

const AlbumPage = () => {
  const [token, setToken] = useState("");
  const [albums, setAlbums] = useState([]);
  const [displayedAlbums, setDisplayedAlbums] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 24;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:725px)");
  const cols = isSmallScreen ? 2 : 4;

  useEffect(() => {
    setToken(localStorage.getItem("access_token"));
    if (token) fetchAlbums(); // Fetch playlists only if token is set
  }, [token]); // Remove currentPage from dependencies

  useEffect(() => {
    // Calculate displayed albums based on currentPage
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayedAlbums(albums.slice(startIndex, endIndex));
  }, [albums, currentPage, itemsPerPage]);

  const fetchAlbums = async () => {
    setLoading(true);
    let fetchedAlbums = [];
    let url = ALBUMS_ENDPOINT; // Set limit to max to reduce calls
    let attempts = 0; // Keep track of the number of attempts

    const fetchAttempt = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        console.log("Fetching playlists from:", url);

        fetchedAlbums.push(...response.data.items); // Accumulate playlists
        url = response.data.next; // Prepare next URL from response
        if (url) await fetchAttempt(); // If there's a next page, continue fetching
      } catch (error) {
        console.error("Error fetching playlists:", error);
        if (error.response && error.response.status === 401 && attempts < 3) {
          // Check for 401 error and limit attempts
          attempts++;
          console.log(`Retry attempt ${attempts} due to 401 error.`);
          setTimeout(fetchAttempt, 2000 * attempts); // Wait 2, 4, 6 seconds before retrying
        } else {
          setLoading(false);
          return; // Exit on error or after 3 attempts
        }
      }
    };

    await fetchAttempt(); // Start the fetching process

    // Update state with all fetched playlists
    setAlbums(fetchedAlbums);
    setTotalPages(Math.ceil(fetchedAlbums.length / itemsPerPage));
    setLoading(false); // End loading
  };

  useEffect(() => {
    if (token) {
      fetchAlbums();
    }
  }, [token]);

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: "#101522",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots color="white" height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <PageMain lightBg={false}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Your Albums
          </Typography>

          <Container maxWidth="md">
            <Pagination
              count={totalPages}
              variant="outlined"
              color="primary"
              size="large"
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#fff",
                },
              }}
            />

            <ImageList gap={25} cols={cols} sx={{ mb: 8 }}>
              {displayedAlbums.map((item, i) => (
                <Link
                  key={item.album.id || i}
                  to="/albumData"
                  state={{ ...item, cover: item.album.images[0]?.url }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ImageListItem sx={{ height: "100% !important" }}>
                    <img
                      src={`${item.album.images[0]?.url}?w=248&fit=crop&auto=format`}
                      alt={item.album.name}
                      loading="lazy"
                      style={{ cursor: "pointer", marginBottom: "1em" }}
                    />
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ color: "#FFF" }}
                    >
                      {item.album.name}
                    </Typography>
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
            {displayedAlbums.length === 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="30vh"
              >
                <Typography variant="h5" component="div" gutterBottom>
                  You have 0 Albums
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: "100%", mt: 4, mb: 2 }}
            >
              <img
                src={SpotifyLogo}
                alt="Spotify Logo"
                style={{ height: 30, width: "auto" }}
              />
            </Box>
          </Container>
        </Box>
      </PageMain>
    </>
  );
};

export default AlbumPage;
