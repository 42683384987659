// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { makeStyles } from "@mui/styles";
// import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
// import { PageMain } from "../../styles/globalStyles";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import Pagination from "@mui/material/Pagination";
// import Typography from "@mui/material/Typography";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { ThreeDots } from "react-loader-spinner";
// import SpotifyLogo from "../../assets/Spotify_Logo_White.png";

// import axios from "axios";

// // const LIKED_SONGS_ENDPOINT = "https://api.spotify.com/v1/me/tracks?limit=24";
// const likedSongUrl = "https://open.spotify.com/collection/tracks";

// const numberToLetterConverter = {
//   0: "C",
//   1: "C#",
//   2: "D",
//   3: "Eb",
//   4: "E",
//   5: "F",
//   6: "F#",
//   7: "G",
//   8: "Ab",
//   9: "A",
//   10: "Bb",
//   11: "B",
// };

// const minorOrMajor = {
//   0: "Minor",
//   1: "Major",
// };

// const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

// // const LIKED_SONGS_ENDPOINT = "https://api.spotify.com/v1/me/tracks";
// // const LIKED_SONGS_ENDPOINT = "https://api.spotify.com/v1/me/tracks";
// const LIKED_SONGS_ENDPOINT = "https://api.spotify.com/v1/me/tracks?limit=24";


// const LikedSongPage = (props) => {
//   const [token, setToken] = useState(localStorage.getItem("access_token"));
//   const [likedSongs, setLikedSongs] = useState([]);
//   const [displayedSongs, setDisplayedSongs] = useState([]);
//   const [audioFeatures, setAudioFeatures] = useState({});
//   const [totalPages, setTotalPages] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isLoading, setLoading] = useState(true);
//   const itemsPerPage = 24;
//   const isSmallScreen = useMediaQuery("(max-width:725px)");
//   const cols = isSmallScreen ? 2 : 4;

//   useEffect(() => {
//     const fetchLikedSongs = async () => {
//       setLoading(true);
//       let fetchedSongs = [];
//       let url = `${LIKED_SONGS_ENDPOINT}`; // Adjust based on Spotify's limits
//       do {
//         try {
//           const response = await axios.get(url, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           });
//           fetchedSongs.push(...response.data.items);
//           url = response.data.next; // Proceed to next page of results if available
//         } catch (error) {
//           console.error("Error fetching liked songs:", error);
//           setLoading(false);
//           return;
//         }
//       } while (url);

//       // Optionally fetch audio features in batches here
//       setLikedSongs(fetchedSongs);
//       setTotalPages(Math.ceil(fetchedSongs.length / itemsPerPage));
//       // Fetch and setup audio features
//       const trackIDs = fetchedSongs.map(song => song.track.id).join(',');
//       console.log(`URL API: https://api.spotify.com/v1/audio-features?ids=${trackIDs}`)
//       try {
//         const audioFeaturesResponse = await axios.get(`https://api.spotify.com/v1/audio-features?ids=${trackIDs}`, {
//           headers: {
//             Authorization: "Bearer " + localStorage.getItem("access_token"),
//             "Content-Type": "application/json",
//           },
//         });
//         const keys = {};
//         audioFeaturesResponse.data.audio_features.forEach(feature => {
//           console.log("feature", feature);
//           keys[feature.id] = {
            
//             key: feature.key,
//             mode: feature.mode,
//           };
//         });
//         setAudioFeatures(keys);
//       } catch (error) {
//         console.error("Error fetching audio features:", error);
//       }

      
//       setLoading(false);
//     };

//     if (token) fetchLikedSongs();
//   }, [token]);

//   useEffect(() => {
//     // Calculate which songs to display based on the current page
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setDisplayedSongs(likedSongs.slice(startIndex, endIndex));
//   }, [currentPage, likedSongs]);

//   if (isLoading) {
//     return (
//       <div
//         style={{
//           backgroundColor: "#101522",
//           height: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <ThreeDots color="white" height={100} width={100} />
//       </div>
//     );
//   }
//   return (
//     <PageMain lightBg={false}>
//       <Box
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         width="100%"
//       >
//         <a
//           href={likedSongUrl}
//           target="_blank"
//           rel="noopener noreferrer"
//           style={{ color: "inherit", textDecoration: "underline" }}
//         >
//           <Typography variant="h4" component="div" gutterBottom>
//             Your Liked Songs
//           </Typography>
//         </a>

//         <Container maxWidth="md">
//           <Pagination
//             count={totalPages}
//             variant="outlined"
//             color="primary"
//             size="large"
//             onChange={(event, value) => {
//               setCurrentPage(value);
//             }}
//             sx={{
//               "& .MuiPaginationItem-root": {
//                 color: "#fff",
//                 marginBottom: "2em",
//               },
//             }}
//           />

//           <ImageList gap={25} cols={cols} sx={{ mb: 2 }}>
//             {displayedSongs.length === 0 ? (
//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="center"
//                 height="30vh"
//                 style={{ marginTop: "4em" }}
//               >
//                 <Typography variant="h5">You have 0 Liked Songs</Typography>
//               </Box>
//             ) : (
//               displayedSongs.map((song, i) => (
//                 <Link
//                   key={song.track.id}
//                   to="/songData"
//                   state={{
//                     track: song.track.name,
//                     trackID: song.track.id,
//                     artists: song.track.album.artists,
//                     audioFeatures: {
//                       key: numberToLetterConverter[audioFeatures[i]?.key],
//                       mode: minorOrMajor[audioFeatures[i]?.mode],
//                     },
//                     cover: song?.track.album.images[0]?.url,
//                   }}
//                   style={{ textDecoration: 'none', color: 'inherit' }}
//                 >
//                   <ImageListItem
//                     sx={{ height: "auto", flexDirection: "column" }}
//                     key={song.track.name}
//                   >
                    
//                     <img
//                       src={`${
//                         song.track.album ? song.track.album.images[0]?.url : ""
//                       }?w=248&fit=crop&auto=format`}
//                       alt={song.track.name}
//                       loading="lazy"
//                       style={{ cursor: "pointer", marginBottom: "0.5em" }}
//                     />
//                     <Typography variant="body2" align="center">
//                       {song.track.name}
//                       {console.log("audiofeatures: ",audioFeatures)}
//                       {console.log(song)}
//                     </Typography>
//                   </ImageListItem>
//                 </Link>
//               ))
//             )}
//           </ImageList>

//           <Box
//             display="flex"
//             justifyContent="center"
//             sx={{ width: "100%", mt: 4, mb: 2 }}
//           >
//             <img
//               src={SpotifyLogo}
//               alt="Spotify Logo"
//               style={{ height: 30, width: "auto" }}
//             />
//           </Box>
//         </Container>
//       </Box>
//     </PageMain>
//   );
// };

// export default LikedSongPage;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { PageMain } from "../../styles/globalStyles";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThreeDots } from "react-loader-spinner"; 
import SpotifyLogo from "../../assets/Spotify_Logo_White.png";


import axios from "axios";

const LIKED_SONGS_ENDPOINT = "https://api.spotify.com/v1/me/tracks?limit=24";

const numberToLetterConverter = {
  0: "C",
  1: "C#",
  2: "D",
  3: "Eb",
  4: "E",
  5: "F",
  6: "F#",
  7: "G",
  8: "Ab",
  9: "A",
  10: "Bb",
  11: "B",
};

const minorOrMajor = {
  0: "Minor",
  1: "Major",
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
const LikedSongPage = (props) => {
  const [token, setToken] = useState("");
  const [data, setData] = useState({});
  const [audioFeatures, setAudioFeatures] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const likedSongUrl = "https://open.spotify.com/collection/tracks";
  const [isLoading, setLoading] = useState(true); 

  const itemsPerPage = 24;
  const [currentPage, setCurrentPage] = useState(1);
  const isSmallScreen = useMediaQuery('(max-width:725px)');
  const cols = isSmallScreen ? 2 : 4;
  useEffect(() => {
    setToken(localStorage.getItem("access_token"));
    fetchLikedSongs();
  }, [token, currentPage]);

  const fetchLikedSongs = async () => {
    scrollToTop()

    try {
      const response = await axios.get(
        `${LIKED_SONGS_ENDPOINT}&offset=${(currentPage - 1) * itemsPerPage}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      );

      let result = response.data;
      setData(result);
      setTotalPages(Math.ceil(result.total / itemsPerPage));
      const IDsReturned = result.items.map((item) => item.track.id);
    console.log("URL", `https://api.spotify.com/v1/audio-features?ids=${IDsReturned.join(",")}`)     
      
      const theData = await axios.get(
        `https://api.spotify.com/v1/audio-features?ids=${IDsReturned.join(
          ","
        )}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      );

      const keys = {};

      for (let x in theData.data.audio_features) {
        keys[x] = {
          key: theData.data.audio_features[x].key,
          mode: theData.data.audio_features[x].mode,
        };
      }

      setAudioFeatures(keys);
      setLoading(false); // Add this line

    } catch (error) {
      setLoading(false); // Add this line

      console.error(error);
    }
  };
  if (isLoading) { // Add this block
    return (
      <div style={{ backgroundColor: '#101522', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ThreeDots color="white" height={100} width={100} />
      </div>
    );
  }
  return (
    <PageMain lightBg={false}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <a
          href={likedSongUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          <Typography variant="h4" component="div" gutterBottom>
            Your Liked Songs
          </Typography>
        </a>
  
        <Container maxWidth="md">
          <Pagination
            count={totalPages}
            variant="outlined"
            color="primary"
            size="large"
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#fff",
                marginBottom: '2em',  
              },
            }}
          />
  
          <ImageList gap={25} cols={cols} sx={{ mb: 2 }}> {/* Reduced margin-bottom */}
            {data?.items?.length === 0 ? (
              <ImageListItem cols={cols}>
                <Box 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center" 
                  height="30vh" 
                  style={{ marginTop: '4em' }}
                > 
                  <Typography variant="h5">You have 0 Liked Songs</Typography>
                </Box>
              </ImageListItem>
            ) : (
              data?.items?.map((song, i) => (
                <Link
                  key={song.track.id}
                  to="/songData"
                  state={{
                    track: song.track.name,
                    trackID: song.track.id,
                    artists: song.track.album.artists,
                    audioFeatures: {
                      key: numberToLetterConverter[audioFeatures[i]?.key],
                      mode: minorOrMajor[audioFeatures[i]?.mode],
                    },
                    cover: song?.track.album.images[0]?.url,
                  }}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ImageListItem
                    sx={{ height: "auto", flexDirection: "column" }}
                    key={song.track.name}
                  >
                    <img
                      src={`${song?.track.album.images[0]?.url}?w=248&fit=crop&auto=format`}
                      alt=""
                      loading="lazy"
                      style={{ cursor: "pointer", marginBottom: "0.5em" }}
                    />
                    <Typography variant="body2" align="center">{song.track.name}</Typography>
                  </ImageListItem>
                </Link>
              ))
            )}
          </ImageList>
  
          {/* Spotify Logo at the bottom, closer to the list */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{ width: '100%', mt: 4, mb: 2 }} // Reduced top and bottom margins
          >
            <img
              src={SpotifyLogo}
              alt="Spotify Logo"
              style={{ height: 30, width: 'auto' }} // Adjust size as needed
            />
          </Box>
        </Container>
      </Box>
    </PageMain>
  );
  
  
  
};

export default LikedSongPage;
