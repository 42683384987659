import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  ButtonContainer,
  
} from "../../styles/globalStyles";

// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";

const HomePage = () => {
  return (
    <>

      <InfoSec lightBg={false}>
      
        <Container>
          <InfoRow imgStart={""}>
            <TextWrapper>
              <Heading lightText={true}>{"Welcome!"}</Heading>
              {/* <Subtitle lightTextDesc={true}>
                {
                  "Browse your Playlists, Albums, Liked Songs, or any song to access musical information from your account."
                }
              </Subtitle> */}
              <Subtitle lightTextDesc={true}>
                {"Browse musical information from your Playlists, Albums, Liked Songs, or any Album or Song on Spotify."}
              </Subtitle>
              <Subtitle lightTextDesc={true}>
                {"Musical information is 🎸 centric, including easy access to "}
                <span style={{ textDecoration: "underline" }}>Key</span>
                {", "}
                <span style={{ textDecoration: "underline" }}>Mode</span>
                {", "}
                <span style={{ textDecoration: "underline" }}>Chords</span>
                {", and "}
                <span style={{ textDecoration: "underline" }}>
                  Pentatonic Shapes
                </span>
                {" from your songs."}
              </Subtitle>
              <ButtonContainer>
                <Link to="/playlists" style={{ textDecoration: "none" }}>
                  <Button big fontBig primary={true}>
                    {"Playlists"}
                  </Button>
                </Link>
                <Link to="/albums" style={{ textDecoration: "none" }}>
                  <Button big fontBig primary={true}>
                    {"Albums"}
                  </Button>
                </Link>
                <Link to="/likedSongs" style={{ textDecoration: "none" }}>
                  <Button big fontBig primary={true}>
                    {"Liked Songs"}
                  </Button>
                </Link>
                <Link to="/search" style={{ textDecoration: "none" }}>
                  <Button big fontBig primary={true}>
                    {"Search"}
                  </Button>
                </Link>

              </ButtonContainer>
            </TextWrapper>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default HomePage;
