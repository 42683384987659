import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import styled from "styled-components";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import MUIContainer from "@mui/material/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop.js";
import SpotifyLogo from "../../assets/Spotify_Logo_Black.png";
import { Box } from "@mui/material";

import { Container, InfoSec, InfoRow } from "../../styles/globalStyles";
import axios from "axios";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin: 0 auto;
  margin-top: -115%; // Negative margin to shift content upwards
  position: relative; // Relative positioning to contain the absolute-positioned results
`;

const Dropdown = styled.div`
  position: absolute; // Absolute positioning for the dropdown
  top: 100%; // Position it right below the search bar
  width: 100%;
  max-height: 36rem;
  overflow: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1; // Optional, to make sure it appears above other elements
`;

const ResultItem = styled.div`
  display: flex; // Use flexbox to align the contents
  align-items: center; // Align items vertically
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid #ccc; // Add a border to the bottom of each item

  &:hover {
    background: #f0f0f0;
  }
`;

const ResultCover = styled.img`
  width: 50px; // Adjust the size as needed
  height: 50px;
  // border-radius: 4px;
  margin-right: 10px; // Space between the image and text
`;

const ResultDetails = styled.div`
  // Style the text details as needed
  font-size: 0.9rem;
`;

const numberToLetterConverter = {
  0: "C",
  1: "C#",
  2: "D",
  3: "Eb",
  4: "E",
  5: "F",
  6: "F#",
  7: "G",
  8: "Ab",
  9: "A",
  10: "Bb",
  11: "B",
};

const minorOrMajor = {
  0: "Minor",
  1: "Major",
};

const Search = () => {
  ScrollToTop();

  const [searchInput, setSearchInput] = useState("");
  // const [tracks, setTracks] = useState([]);
  const [audioFeatures, setAudioFeatures] = useState({});
  const [debouncedSearchInput] = useDebounce(searchInput, 300);
  // const [searchType, setSearchType] = useState("track"); // Added to determine search type
  const [results, setResults] = useState([]); // Unified results for tracks and albums
  const [selectedAudioFeatures, setSelectedAudioFeatures] = useState(null);

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

  const TOKEN_URL = "https://accounts.spotify.com/api/token";
  const getAccessToken = async () => {
    const auth = `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`;
    const headers = {
      Authorization: auth,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");

    try {
      const response = await axios.post(TOKEN_URL, params, { headers });
      return response.data.access_token;
    } catch (error) {
      console.error(
        "Failed to fetch access token",
        error.response?.data || error
      );
    }
  };
  const search = async () => {
    if (!debouncedSearchInput) return;

    const accessToken = await getAccessToken();
    if (!accessToken) return;

    try {
      const response = await axios.get(
        `https://api.spotify.com/v1/search?q=${debouncedSearchInput}&type=album,track&limit=3`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const trackItems = response.data.tracks.items.map((item) => ({
        ...item,
        itemType: "track",
      }));
      const albumItems = response.data.albums.items.map((item) => ({
        ...item,
        itemType: "album",
      }));
      const combinedResults = [...trackItems, ...albumItems];

      const trackIDs = trackItems.map((track) => track.id);

      if (trackIDs.length > 0) {
        const featuresResponse = await axios.get(
          `https://api.spotify.com/v1/audio-features?ids=${trackIDs.join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const newAudioFeatures = {};
        featuresResponse.data.audio_features.forEach((feature, index) => {
          if (feature) {
            newAudioFeatures[trackIDs[index]] = {
              key: feature.key,
              mode: feature.mode,
            };
          }
        });

        setAudioFeatures(newAudioFeatures);
      }

      setResults(combinedResults);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (debouncedSearchInput) {
      search();
    }
  }, [debouncedSearchInput]);

  return (
    <InfoSec lightBg={false}>
      <Container>
        <InfoRow imgStart={""}>
          <SearchContainer>
            <InputGroup className="mb-2" size="md">
              <FormControl
                placeholder="Start Typing..."
                type="input"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
                onChange={(event) => {
                  setSearchInput(event.target.value);
                  if (event.target.value === "") {
                    setResults([]);
                  }
                }}
              />
            </InputGroup>

            {results.length > 0 && (
              <Dropdown>
                {/* Songs Section */}
                <div>
                  <h6
                    style={{
                      color: "#000",
                      fontSize: "1.0rem",
                      fontFamily: "inherit",
                      paddingLeft: "10px",
                      paddingTop: "7px",
                      marginBottom: "7px", // Adjust this value to reduce the bottom margin
                    }}
                  >
                    Songs
                  </h6>
                  {results
                    .filter((result) => result.itemType === "track")
                    .slice(0, 3) // Take only the first three tracks
                    .map((result, i) => (
                      <Link
                        key={i}
                        to="/songData"
                        state={{
                          album: {
                            id: result.id,
                            name: result.name,
                          },
                          artists: result.artists,
                          track: result.name,
                          trackID: result.id,
                          cover: result.album.images[0].url,
                          audioFeatures: {
                            key: numberToLetterConverter[
                              audioFeatures[result.id]?.key
                            ],
                            mode: minorOrMajor[audioFeatures[result.id]?.mode],
                          },
                        }}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <ResultItem>
                          <ResultCover
                            src={result.album.images[0].url}
                            alt={result.name}
                          />
                          <ResultDetails>{result.name}</ResultDetails>
                        </ResultItem>
                      </Link>
                    ))}
                </div>

                {/* Albums Section */}
                <div>
                  <h6
                    style={{
                      color: "#000",
                      fontSize: "1.0rem",
                      fontFamily: "inherit",
                      paddingLeft: "10px",
                      paddingTop: "7px",
                      marginBottom: "7px",
                    }}
                  >
                    Albums
                  </h6>
                  {results
                    .filter((result) => result.itemType === "album")
                    .slice(0, 3) // Take only the first three albums
                    .map((result, i) => (
                      <Link
                        key={i}
                        to="/albumData"
                        state={{
                          album: {
                            id: result.id,
                            name: result.name,
                          },
                          cover: result.images[0].url,
                        }}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <ResultItem>
                          <ResultCover
                            src={result.images[0].url}
                            alt={result.name}
                          />
                          <ResultDetails>{result.name}</ResultDetails>
                        </ResultItem>
                      </Link>
                    ))}

                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ width: "100%", mt: 1, mb: 1 }}
                  >
                    <img
                      src={SpotifyLogo}
                      alt="Spotify Logo"
                      style={{ height: 23, width: "auto" }} // Adjust size as needed
                    />
                  </Box>
                </div>
              </Dropdown>
            )}
          </SearchContainer>
        </InfoRow>
      </Container>
    </InfoSec>
  );
};

export default Search;
