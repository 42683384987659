import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../styles/globalStyles";
import SongSeekrLogo from "../../assets/Spot-N-FindBlack.png";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { ButtonLink, SmallButton } from "../../styles/globalStyles";
import { useNavigate } from "react-router-dom";

// import Icon from '@mui/material/Icon';
import Icon from "@mui/material/Icon";

import {
  Nav,
  MaxLogo,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItemBtn,
  NavBtnLink,
  NavIcon,
  NavLinks,
  NavItem,
} from "./NavBarStyling";
const redirect_uri = process.env.REACT_APP_REDIRECT_URI;

// const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirect_uri}&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state%20playlist-read-private`;
const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirect_uri}&scope=user-library-read%20playlist-read-private`;

const NavBar = ({ code }) => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const history = useNavigate();

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const closeMenuAndNavigateToPlaylists = () => {
    closeMobileMenu();
    history("/playlists");
  };
  const closeMenuAndNavigateToAlbums = () => {
    closeMobileMenu();
    history("/albums");
  };
  const closeMenuAndNavigateToLikedSongs = () => {
    closeMobileMenu();
    history("/likedSongs");
  };
  const closeMenuAndNavigateToSearch = () => {
    closeMobileMenu();
    history("/search");
  };
  const closeMenuAndNavigateToAbout = () => {
    history("/about");
    closeMobileMenu();
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
    console.log("logged out!");
  };
  const goToAUTH_URL = () => {
    window.location.replace(AUTH_URL);
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
          <NavLogo to="/" onClick={() => { closeMobileMenu(); scrollToTop(); }}>
              <MaxLogo src={SongSeekrLogo} alt="logo" />
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  fontFamily: "'Trebuchet MS', sans-serif",
                  textDecoration: "underline",
                }}
              >
                songseekr
              </Typography>
            </NavLogo>
            {code ? (
              <>
                <MobileIcon onClick={handleClick}>
                  {click ? <FaTimes /> : <FaBars />}
                </MobileIcon>
                <NavMenu onClick={handleClick} click={click}>
                  {/* <NavItemBtn>
                    <SmallButton onClick={closeMobileMenu} primary>
                      <ButtonLink to="/search">Search</ButtonLink>
                    </SmallButton>
                  </NavItemBtn> */}
                  <NavItemBtn>
                    <SmallButton
                      onClick={closeMenuAndNavigateToPlaylists}
                      primary
                    >
                      {/* <ButtonLink to="/playlists"> */}
                      Playlists
                      {/* </ButtonLink> */}
                    </SmallButton>
                  </NavItemBtn>
                  <NavItemBtn>
                    <SmallButton onClick={closeMenuAndNavigateToAlbums} primary>
                      {/* <ButtonLink to="/albums"> */}
                      Albums
                      {/* </ButtonLink> */}
                    </SmallButton>
                  </NavItemBtn>

                  <NavItemBtn>
                    <SmallButton
                      onClick={closeMenuAndNavigateToLikedSongs}
                      primary
                    >
                      {/* <ButtonLink to="/likedSongs"> */}
                      Liked Songs
                      {/* </ButtonLink> */}
                    </SmallButton>
                  </NavItemBtn>
                  <NavItemBtn>
                    <SmallButton onClick={closeMenuAndNavigateToSearch} primary>
                      {/* <ButtonLink to="/search"> */}
                      Search
                      {/* </ButtonLink> */}
                    </SmallButton>
                  </NavItemBtn>
                  <NavItemBtn>
                    <SmallButton onClick={closeMenuAndNavigateToAbout} primary>
                      {/* <ButtonLink to="/albums"> */}
                      About
                      {/* </ButtonLink> */}
                    </SmallButton>
                  </NavItemBtn>

                  <NavItemBtn>
                    <SmallButton onClick={logout} primary>
                      Log Out
                    </SmallButton>
                  </NavItemBtn>
                  <NavItemBtn></NavItemBtn>
                </NavMenu>
              </>
            ) : (
              <>
                <MobileIcon onClick={handleClick}>
                  {click ? <FaTimes /> : <FaBars />}
                </MobileIcon>
                <NavMenu onClick={handleClick} click={click}>
                <NavItemBtn to="/">
                    <SmallButton onClick={goToAUTH_URL} primary>
                      Connect
                    </SmallButton>
                  </NavItemBtn>

                <NavItemBtn>
                    <SmallButton onClick={closeMenuAndNavigateToSearch} primary>
                      Search
                    </SmallButton>
                  </NavItemBtn>
                  <NavItemBtn>
                    <SmallButton onClick={closeMenuAndNavigateToAbout} primary>
                      About
                    </SmallButton>
                  </NavItemBtn>

                 
                  {/* make buttons go higher */}
                  <NavItemBtn>
                  </NavItemBtn>
                  <NavItemBtn>
                  </NavItemBtn>
                  
                  {/* <NavItemBtn>
                    <SmallButton onClick={closeMobileMenu} primary>
                      <ButtonLink to="/search">Search</ButtonLink>
                    </SmallButton>
                  </NavItemBtn> */}
                </NavMenu>
              </>
            )}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;
