import React from "react";

import { Button } from "../../styles/globalStyles";
import SongSeekrLogo from "../../assets/Spot-N-FindBlack.png";
import Typography from "@mui/material/Typography";
// import ScrollToTop from "../../ScrollToTop.js";

import { FaInstagram, FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";
import {
  LogoIcon,
  MaxLogo,
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  SocialIcons,
  SocialIconLink,
} from "./FooterStyle";

const Footer = () => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // optional, for smooth scrolling
      });
    };

  return (
    <>
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
        <SocialLogo to="/" onClick={scrollToTop}>
            <MaxLogo src={SongSeekrLogo} alt="logo" />
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontFamily: "'Trebuchet MS', sans-serif", textDecoration: "underline" }}
            >
              songseekr
            </Typography>
          </SocialLogo>
          <SocialIcons>
            <SocialIconLink
              href="https://github.com/MaxF8"
              target="_blank"
              aria-label="Github"
            >
              <FaGithub />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.linkedin.com/in/max-friedman-98a77a205/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
    </>
  );
  
};

export default Footer;
