


import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import SpotifyWebApi from "spotify-web-api-node";
import { ThreeDots } from "react-loader-spinner";
import TableContainer from "@mui/material/TableContainer";
import { PageMain } from "../../styles/globalStyles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import SpotifyLogo from "../../assets/Spotify_Logo_White.png";

const numberToLetterConverter = {
  0: "C",
  1: "C#",
  2: "D",
  3: "Eb",
  4: "E",
  5: "F",
  6: "F#",
  7: "G",
  8: "Ab",
  9: "A",
  10: "Bb",
  11: "B",
};
const minorOrMajor = {
  0: "Minor",
  1: "Major",
};
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
const getAllTrackIDs = (data) => {
  const listOfIDs = [];

  for (let x in data) {
    listOfIDs[x] = data[x].id.toString();
  }

  return listOfIDs;
};

const AlbumData = (props) => {
  scrollToTop();
  const location = useLocation();
  const state = location.state;

  const albumID = state.album.id;
  const albumCover = state.cover;
  const albumName = state.album.name;
  const isSmallScreen = useMediaQuery("(max-width:725px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:400px)");

  const textSize = isExtraSmallScreen ? "h6" : isSmallScreen ? "h5" : "h4";

  const albumUrl = `https://open.spotify.com/album/${albumID}`;

  const itemsPerPage = 16;
  const [currentPage, setCurrentPage] = useState(1);

  const [token, setToken] = useState("");
  const [data, setData] = useState({});
  const [audioFeatures, setAudioFeatures] = useState({});
  const [isLoading, setLoading] = useState(true);

  const ALBUM_ENDPOINT = `https://api.spotify.com/v1/albums/${albumID}`;
  const AUDIO_FEATURES_ENDPOINT = "https://api.spotify.com/v1/audio-features";

  function convertMsToMinSec(ms) {
    let minutes = Math.floor(ms / 60000);
    let seconds = ((ms % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

  const TOKEN_URL = "https://accounts.spotify.com/api/token";
  const getAccessToken = async () => {
    const auth = `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`;
    const headers = {
      Authorization: auth,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");

    try {
      const response = await axios.post(TOKEN_URL, params, { headers });
      return response.data.access_token;
    } catch (error) {
      console.error(
        "Failed to fetch access token",
        error.response?.data || error
      );
    }
  };

  useEffect(() => {
    fetchAlbums();
  }, [token, currentPage]);

  const fetchAlbums = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) return;
    const endpoint = `${ALBUM_ENDPOINT}?limit=${itemsPerPage}&offset=${
      (currentPage - 1) * itemsPerPage
    }`;

    await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.tracks);
        // Get trackIDs here after the tracks state has been set
        const trackIDs = data.tracks.items.map((track) => track.id.toString());

        return axios.get(
          `https://api.spotify.com/v1/audio-features?ids=${trackIDs.join(",")}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((response) => {
        if (response) {
          const keys = {};
          for (let x in response.data.audio_features) {
            keys[x] = {
              key: response.data.audio_features[x].key,
              mode: response.data.audio_features[x].mode,
            };
          }
          setAudioFeatures(keys);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: "#101522",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots color="white" height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <PageMain lightBg={false}>
        <Container maxWidth="md" sx={{ mb: 3 }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
          >
            <Box
              minHeight="20vh"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <img
                src={albumCover}
                alt=""
                loading="lazy"
                style={{
                  cursor: "pointer",
                  width: "175px",
                  height: "175px",
                  objectFit: "cover",
                  marginBottom: "1rem", // Adds some space between image and table
                  marginRight: "1.2rem", // Adds space to the right of the image
                }}
              />

              <Typography variant={textSize} component="div" gutterBottom>
                <a
                  href={albumUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit", textDecoration: "underline" }} // This will underline the link
                >
                  {albumName}
                </a>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "1em",
              }}
            >
              {/* {console.log(data)} */}
              <Pagination
                count={Math.ceil(data?.items.length / itemsPerPage)}
                variant="outlined"
                color="primary"
                size="large"
                onChange={(event, value) => {
                  setCurrentPage(value);
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#fff", // Change color of text
                  },
                }}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                overflow: "auto", // Enable scrolling

                width: "100%",
                marginBottom: 3,
                backgroundColor: "#101522",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow style={{ borderTop: "2px solid #ddd" }}>
                    <TableCell
                      style={{
                        borderBottom: "2px solid #ddd",
                        borderLeft: "2px solid #ddd",
                        borderRight: "2px solid #ddd",
                        color: "#fff",
                      }}
                    >
                      Song
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "2px solid #ddd",
                        borderLeft: "2px solid #ddd",
                        borderRight: "2px solid #ddd",
                        color: "#fff",
                      }}
                    >
                      Key
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "2px solid #ddd",
                        borderLeft: "2px solid #ddd",
                        borderRight: "2px solid #ddd",
                        color: "#fff",
                      }}
                    >
                      Mode
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "2px solid #ddd",
                        borderLeft: "2px solid #ddd",
                        borderRight: "2px solid #ddd",
                        color: "#fff",
                      }}
                    >
                      Artist
                    </TableCell>
                    {/* <TableCell style={{ borderBottom: "none", color: "#fff" }}>
                      Album Title
                    </TableCell> */}
                    <TableCell
                      style={{
                        borderBottom: "2px solid #ddd",
                        borderLeft: "2px solid #ddd",
                        borderRight: "2px solid #ddd",
                        color: "#fff",
                      }}
                    >
                      Duration
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {console.log(data)} */}
                  {data?.items.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                    ? data.items
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((item, x) => {
                          return (
                            <TableRow
                              key={item.name}
                              style={{
                                borderBottom: "2px solid #ddd",
                                borderLeft: "2px solid #ddd",
                                borderRight: "2px solid #ddd",
                                color: "#fff",
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  borderBottom: "2px solid #ddd",
                                  borderLeft: "2px solid #ddd",
                                  borderRight: "2px solid #ddd",
                                  color: "#fff",
                                }}
                              >
                                <Link
                                  to="/songData"
                                  state={{
                                    track: item.name,
                                    artists: item.artists,
                                    trackID: item.id,

                                    audioFeatures: {
                                      key: numberToLetterConverter[
                                        audioFeatures[x].key
                                      ],
                                      mode: minorOrMajor[audioFeatures[x].mode],
                                    },
                                    cover: albumCover,
                                  }}
                                  style={{ color: "inherit" }}
                                >
                                  {item.name}
                                </Link>
                              </TableCell>

                              <TableCell
                                style={{
                                  borderBottom: "2px solid #ddd",
                                  borderLeft: "2px solid #ddd",
                                  borderRight: "2px solid #ddd",
                                  color: "#fff",
                                }}
                              >
                                {numberToLetterConverter[audioFeatures[x].key]}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "2px solid #ddd",
                                  borderLeft: "2px solid #ddd",
                                  borderRight: "2px solid #ddd",
                                  color: "#fff",
                                }}
                              >
                                {minorOrMajor[audioFeatures[x].mode]}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "2px solid #ddd",
                                  borderLeft: "2px solid #ddd",
                                  borderRight: "2px solid #ddd",
                                  color: "#fff",
                                }}
                              >
                                {item.artists.map((artist, index) => (
                                  <span key={artist.id}>
                                    <a
                                      href={`https://open.spotify.com/artist/${artist.id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "inherit",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {artist.name}
                                    </a>
                                    {/* Add comma for multiple artists except the last one */}
                                    {index !== item.artists.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                ))}
                              </TableCell>
                              {/* <TableCell
                                style={{ borderBottom: "none", color: "#fff" }}
                              >
                                {albumName}
                              </TableCell> */}
                              <TableCell
                                style={{
                                  borderBottom: "2px solid #ddd",
                                  borderLeft: "2px solid #ddd",
                                  borderRight: "2px solid #ddd",
                                  color: "#fff",
                                }}
                              >
                                {convertMsToMinSec(item.duration_ms)}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
            display="flex"
            justifyContent="center"
            sx={{ width: "100%", mt: 4, mb: 2 }} // Adjust top and bottom margins
          >
            <img
              src={SpotifyLogo}
              alt="Spotify Logo"
              style={{ height: 30, width: "auto" }} // Adjust size as needed
            />
          </Box>
          </Box>
          
        </Container>
      </PageMain>
    </>
  );
};
export default AlbumData;


