import React from 'react';
import { createRoot } from "react-dom/client";

import App from './App.js';
import GlobalStyle from './styles/globalStyles';

const root = createRoot(document.getElementById("root"));
root.render(
  <>
    <GlobalStyle />
    <App />
  </>
  );

